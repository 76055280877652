<template>
    <b-container>
        <b-container class="py-0">
            <p class="txt_secondary text-uppercase pt-3"><strong>Información de general</strong></p>
            <template>
                <b-breadcrumb class="breadcrumb-color">
                    <b-breadcrumb-item :to="{ name: 'preoperatorio'}">
                        <!-- <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon> -->
                            <span class="txt_secondary text-uppercase">Preoperatorio</span>
                        </b-breadcrumb-item>
                        <b-breadcrumb-item :to="{ name: 'transoperatorio'}">
                            <span class="txt_secondary text-uppercase">Transoperatorio</span>
                        </b-breadcrumb-item>
                    <b-breadcrumb-item :to="{ name: 'postoperatorio'}">
                        <span class="txt_secondary text-uppercase">Postoperatorio</span>
                    </b-breadcrumb-item>
                </b-breadcrumb>
            </template>
            <b-row>
                <b-col cols="12">
                    <router-view />
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'registroQuirofano',
    components:{ },
    data:() => ({
        busy: false,
        paciente:{},
    }),
    methods: {
        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
}
</script>